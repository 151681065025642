<template>
  <div>
    <h4 class="w-100 text-left mb-3 mt-2 pr-3">
      Вы уверены, что хотите отказаться от автопродления подписки?
    </h4>
    <div class="p-3 w-100" style="background: #fffce3; border-radius: 10px">
      <div class="mb-4 d-flex" v-for="btn in services" :key="`${btn.id} ${btn.icon_desktop}`">
        <img
          style="width: 80px; height: 80px"
          :src="btn.icoв_desktop"
          :alt="btn.text"
          :disabled="btn.disabled"
          :title="btn.text"
          class="mr-3"
        />
        <p>{{ btn.description }}</p>
      </div>
      <b-button variant="link">Все преимущества подписки</b-button>
    </div>
  </div>
</template>

<script>
import ui from '@main/api/ui';
import { mapState } from 'vuex';

export default {
  data: () => ({
    services: [],
  }),
  computed: {
    ...mapState(['currentUser']),
  },
  async mounted() {
    const res = await ui.buttons.activeServices.get({ incoming: false });
    this.services = res.results.slice(0, 4);
  },
};
</script>
